<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <h1 class="mt-50" style="color: white">
        404: {{ $t("labels.pageNotFound") }}
      </h1>
    </div>
  </div>
</template>


<script>
export default {
  name: "PageNotFoundPage",
  components: {},
  data() {
    return {};
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
};
</script>

<style scoped>
</style>